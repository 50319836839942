<template>
  <div class="custom-text-input custom-combobox">
    <div :class="headerClass || 'field__subheader d-flex'">
      <span
        v-if="required"
        class="field__subheader--required"
      >
        *
      </span>
      {{ header }}
      <slot name="icon" />
    </div>
    <div v-if="subheader" class="field__subheader pt-2">
      {{ subheader }}
    </div>
    <validation-observer ref="textInput">
      <validation-provider
        v-slot="{ errors }"
        :rules="{
          required
        }"
        mode="eager"
      >
        <v-combobox
          :value="value"
          :placeholder="placeholder"
          :multiple="multiple"
          dense
          :items="items"
          class="custom-dropdown mt-4"
          outlined
          :menu-props="{
            offsetY: true,
            'content-class': 'custom-dropdown',
          }"
          :item-value="itemValue || 'value'"
          :item-text="itemText || 'label'"
          append-icon="mdi-chevron-down mt-1"
          :error-messages="errors"
          @change="(newValue) => $emit('change', newValue)"
          @input="(newValue) => $emit('input', newValue)"
        />
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: "CustomCombobox",
  props: {
    header: {
      default: () => {},
      type: String,
    },
    headerClass: {
      default: () => {},
      type: String,
    },
    subheader: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => {},
      type: String,
    },
    multiple: {
      default: () => {},
      type: Boolean,
    },
    items: {
      default: () => [],
      type: Array,
    },
    value: {
      default: () => null,
    },
    itemValue: {
      default: () => null,
    },
    itemText: {
      default: () => null,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-combobox {
  .v-input__slot {
    .v-select__selections {
      .v-select__selection {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.87);
      }
    }
    .v-input__icon {
      i {
        color: #4c4c4c !important;
        font-size: 15px;
      }
    }
  }
  .v-list-item__title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.87);
  }
  .v-input__append-inner {
    margin-right: -6px;
    padding-left: 0 !important;
  }


}

.field__subheader--required {
  color: #e74c3c;
}
</style>
