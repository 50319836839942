<template>
  <v-container class="manager-user mt-5 px-10" fluid>
    <PageHeader
      :header-text="
        userId === null ? 'New User' : 'Edit User'
      "
      class="mb-7"
    >
      <template #subheader>
        <div>Fill out the form below to add a new user to this account</div>
      </template>
    </PageHeader>

    <validation-observer ref="textInput">
      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model.trim="user.firstName"
            :maxlength="150"
            required
            header="First Name"
            header-class="field__header"
            placeholder="Your first name"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model.trim="user.lastName"
            required
            :maxlength="150"
            header="Last Name"
            header-class="field__header"
            placeholder="Your last name"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model.trim="user.email"
            required
            email
            :maxlength="150"
            header="Email Address"
            header-class="field__header"
            placeholder="Your email"
          />
        </v-col>
      </v-row>

      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            v-model="user.isActive"
            header="Status"
            header-class="field__header"
            :items="user_statuses"
            item-text="name"
            item-value="value"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTelInput
            v-model="user.phoneNumber"
            :maxlength="150"
            :minlength="8"
            header="Phone Number"
            header-class="field__header"
            placeholder="Your phone number"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomCombobox
            v-model="user.company"
            header="Company"
            header-class="field__header"
            :items="user_companies"
            item-value="company"
            item-text="company"
            required
          />
        </v-col>
      </v-row>

      <v-divider class="mt-9 mb-8" />

      <!-- password -->
      <div class="field__header mb-1">
        Password Information
      </div>
      <v-radio-group
        v-model="user.password_method"
        row
        class="custom-field-radio-group mb-2"
      >
        <v-radio
          v-if="(userId !== null)"
          label="Keep existing password"
          value="keep_password"
          class="mx-3"
        />
        <v-radio
          label="Send welcome email"
          value="welcome_email"
          class="mx-3"
        />
        <v-radio label="Set password manually" value="manual" class="mx-3" />
      </v-radio-group>
      <v-row v-if="user.password_method === 'manual'">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model="user.plainPassword"
            type="password"
            :maxlength="150"
            :passmin="8"
            required
            header="Password"
            header-class="field__header"
            placeholder="Enter your password"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model="user.password_confirm"
            type="password"
            :maxlength="150"
            :passmin="8"
            :password_to_compare="user.plainPassword"
            required
            header="Confirm Password"
            header-class="field__header"
            placeholder="Confirm your password"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="py-0"
          :class="{
            'password--weak': password_strength === 'Weak',
            'password--medium': password_strength === 'Medium',
            'password--strong': password_strength === 'Strong',
          }"
        >
          <div class="d-flex align-center justify-space-between">
            <div class="field__header mb-1">
              Password Strength
            </div>
            <div
              v-if="user.plainPassword && user.plainPassword.length > 0"
              class="password-strength mb-1"
            >
              {{ password_strength }}
            </div>
          </div>
          <div class="password-strength-bar mt-7">
            <v-progress-linear
              background-color="#dee7ee"
              :value="password_strength_points"
              rounded
              height="8"
            />
          </div>
        </v-col>
      </v-row>
    </validation-observer>
    <v-divider class="mt-4 mb-8" />

    <!-- is admin -->
    <v-row class="py-0">
      <v-col cols="12" sm="4" class="py-0">
        <div class="field__header mb-1">
          Pulse Admin

          <v-tooltip bottom>
            <template
              #activator="{ on, attrs }"
            >
              <v-icon
                color="#2b84eb"
                size="20"
                class="ml-1"
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Set this User as Administrator of the system.</span>
          </v-tooltip>
        </div>

        <CustomDropdown
          v-model="user.isPulseAdmin"
          subheader="An admin can add or remove accounts"
          :items="[
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ]"
          item-value="value"
        />
      </v-col>
    </v-row>

    <!-- account roles -->
    <v-container
      v-if="isLoaded && user.isPulseAdmin === false"
      class="account-roles pa-0"
      fluid
    >
      <v-divider class="mt-4 mb-8" />

      <v-row>
        <v-col cols="11" sm="6" md="8" lg="7" class="pt-4 pb-6">
          <v-row>
            <v-col class="py-0 pr-2" cols="7">
              <div class="field__header">
                Account
              </div>
            </v-col>
            <v-col class="py-0 pl-2" cols="5">
              <div class="field__header">
                Role
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        v-for="(item, index) in user.accountRoles"
        :key="index"
        class="d-flex align-center mb-4"
      >
        <v-col cols="11" sm="11" md="8" lg="7" class="py-0">
          <v-row>
            <v-col class="py-0 pr-3" cols="7">
              <CustomCombobox
                v-model="user.accountRoles[index].account"
                placeholder="Select an account"
                :items="user_accounts"
                item-text="name"
                item-value="accountId"
                @change="(account) => submitAddNewAccount(account, index)"
              />
            </v-col>
            <v-col class="py-0 pl-2" cols="5">
              <CustomDropdown
                v-model="user.accountRoles[index].role"
                placeholder="Select a role"
                :items="user_roles"
                item-text="name"
                item-value="value"
                @change="submitAddNewAccount(null, index)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="1"
          sm="1"
        >
          <v-btn
            class="mt-md-3"
            icon
            small
            @click="deleteUserAccount(index)"
          >
            <v-icon color="#66788e" size="16">
              mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div
        class="simple-text-button button--blue my-3"
        @click="addUserAccount"
      >
        Add Another Account +
      </div>
    </v-container>

    <!-- buttons -->
    <v-row class="mt-12 mb-10">
      <v-col cols="12" class="d-flex justify-end flex-wrap">
        <v-btn
          v-if="user.id && audits.length > 0"
          class="custom-button custom-button--gray mr-sm-5 mb-4 mb-sm-0"
          height="34px"
          width="150px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="dialog = true"
        >
          View Audit History
        </v-btn>
        <v-btn
          class="custom-button custom-button--blue px-13"
          height="34px"
          depressed
          width="150px"
          :block="$vuetify.breakpoint.xsOnly"
          @click="save_user"
        >
          Save User
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-if="user.id" v-model="dialog" scrollable max-width="700px">
      <AuditHistoryDialog
        v-if="dialog"
        :audits="audits"
        @dismiss="dialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import AuditHistoryDialog from "@/views/Users/components/AuditHistoryDialog";
import CustomCombobox from "@/sharedComponents/CustomCombobox";
import CustomTelInput from "@/sharedComponents/CustomTelInput";
import sortBy from "lodash/sortBy";

export default {
  name: "ManagerUserView",
  metaInfo: {
    title: 'User Management'
  },
  components: {
    CustomCombobox,
    PageHeader,
    CustomTextInput,
    CustomDropdown,
    AuditHistoryDialog,
    CustomTelInput,
  },
  props: {
    user_prop: {
      default: () => {},
      type: Object,
    }
  },
  data() {
    return {
      userId: null,
      user: {
        firstName: null,
        lastName: null,
        email: null,
        isActive: true,
        company: null,
        phoneNumber: null,
        plainPassword: null,
        password_confirm: null,
        isPulseAdmin: false,
        accountRoles: [],
        password_method: "welcome_email",
      },
      dialog: false,
      user_statuses: [
        { name: "Active", value: true },
        { name: "Inactive", value: false },
      ],
      user_accounts: [],
      user_companies: [],
      user_roles: [
        { name: "User", value: "ROLE_USER" },
        { name: "Read Only", value: "ROLE_READ_ONLY" },
        { name: "Reporting", value: "ROLE_REPORTING" },
        { name: "No Contact Data", value: "ROLE_NO_CONTACT_DATA" },
      ],
      isLoaded: false,
      audits: [],
    };
  },
  computed: {
    password_strength() {
      if (this.user.plainPassword) {
        if (this.user.plainPassword.length < 7) return "Weak";
        else if (this.user.plainPassword.length < 10) return "Medium";
        else return "Strong";
      }
      return null;
    },
    password_strength_points() {
      return this.user.plainPassword ? this.user.plainPassword.length * 8 : 0;
    },
  },
  async created() {
    await this.get_accounts();
    await this.get_companies();

    if (this.$route.params.id) {
      this.userId = this.$route.params.id;
      this.user.password_method = "keep_password";
      await this.getUser();
    }

    this.isLoaded = true;
  },
  methods: {
    async getUser() {
      try {
        const result = await this.$rest.admin.get_resource(this.$route.params.id);
        const accountResult = await this.$rest.user.getUserAccounts(this.$route.params.id);

        this.user = {
          ...this.user,
          ...result.data,
          accountRoles: accountResult.data?.map(item => ({
            ...item,
            role: item.roles[0] || null,
            accountId: item.account.accountId || null,
          })) ?? [],
        }

      } catch (e) {
        this.$router.push({name: "ManagerUserList"});
      }
    },
    async get_accounts() {
      const resp = await this.$rest.account.get_collection({ignorePagination: true});
      if (!resp.data) {
        return;
      }

      this.user_accounts = sortBy(resp.data.items, 'name');
    },
    async get_companies() {
      const resp = (await this.$rest.user.get_user_companies()).data;
      this.user_companies = resp;
    },
    async save_user() {
      try {
        if (await this.validate()) {
          const userData = {
            ...this.user,
            accountRoles: this.user.accountRoles.map(item => ({
              ...item,
              roles: [item.role],
              accountId: item.account.accountId || null
            })),
          };
          // save
          if (userData.plainPassword === null) {
            this.$delete(userData, 'plainPassword')
          }
          if (this.user.id) {
            await this.$rest.admin.put_resource(userData.id, userData)
            this.$notifier.success("Successfully edited user")
          } else {
            await this.$rest.admin.post_resource(userData)
            this.$notifier.success("Successfully added user")
          }

          this.$router.push({
            name: "ManagerUserList",
          });
        }
      } catch (e) {
        console.error(e);
      }
    },

    async validate() {
      return await this.$refs.textInput.validate();
    },
    reset() {
      this.$refs.textInput.reset();
    },
    resetValidation() {
      this.$refs.textInput.resetValidation();
    },

    async deleteUserAccount(index) {
      if (!this.user.id || !this.user.accountRoles[index].id) {
        this.user.accountRoles.splice(index, 1);
        return;
      }

      try {
        await this.$rest.user.deleteUserAccount(
          this.user.id,
          this.user.accountRoles[index].account.accountId
        );

        this.$store.commit("snackbar/showMessage", {
          color: "info",
          content: `Deleted account ${this.user.accountRoles[index].account.name} of user.`,
        });

        this.user.accountRoles.splice(index, 1);
      } catch (e) {
        this.$store.commit("snackbar/showMessage", {
          color: "error",
          content: "Delete account of user failed. Please try again.",
        });
      }
    },
    addUserAccount() {
      this.user.accountRoles.push({
        account: '',
        role: '',
        id: null,
      })
    },
    async submitAddNewAccount(accountId, index) {
      const userAccount = this.user.accountRoles[index];
      if (accountId) {
        const dupItems = this.user.accountRoles.filter(item => {
          return item.account === accountId
        });
        if (dupItems.length > 1) {
          userAccount.account = '';
          return this.$store.commit("snackbar/showMessage", {
            color: "warning",
            content: "Please select an unique Account",
          });
        }
      }

      if (!this.user.id || userAccount.id) {
        return;
      }

      if (!userAccount.account || !userAccount.role) {
        return;
      }

      try {
        const account = this.user_accounts.find(item => item.accountId === userAccount.account);
        await this.$rest.user.addUserAccount(this.user.id, account.accountId, {
          roles: [userAccount.role],
        })

        userAccount.id = _.random(1, 9999);
        this.$set(this.user.accountRoles[index], 'account', account);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.manager-user {
  .password-strength {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #de2828;
  }
  .password--weak .password-strength {
    color: #de2828;
  }
  .password--medium .password-strength {
    color: #fecf33;
  }
  .password--strong .password-strength {
    color: #1f9f43;
  }

  ::v-deep .password-strength-bar {
    .v-progress-linear {
      background-color: #dee7ee;
    }
    .v-progress-linear__determinate {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      background: linear-gradient(to right, #f07676 0%, #dc2929 71%);
    }
  }

  ::v-deep .password--medium .password-strength-bar .v-progress-linear .primary {
    background-color: linear-gradient(to right, #ffe762, #fdc81a) !important;
    background: linear-gradient(to right, #ffe762, #fdc81a) !important;
  }
  ::v-deep .password--strong .password-strength-bar .v-progress-linear .primary {
    background-color: linear-gradient(to left, #1f9f43, #47d770) !important;
    background: linear-gradient(to left, #1f9f43, #47d770) !important;
  }

  .account-roles {
    .v-text-field__details {
      display: none;
    }
  }

  ::v-deep .combobox.field__subheader {
    font-size: 15px;
    font-weight: 600;
    color: #241c15;
    margin-bottom: 15px;
  }

  ::v-deep .custom-dropdown {
    .field__subheader_item {
      font-family: "Open Sans", sans-serif;
      font-size: 13px !important;
      font-weight: normal !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #66788e;
    }
  }

  ::v-deep .v-input--radio-group__input {
    .v-label {
      font-size: 13px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #241c15;
    }
  }
}
</style>
