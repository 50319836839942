var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-text-input custom-combobox"},[_c('div',{class:_vm.headerClass || 'field__subheader d-flex'},[(_vm.required)?_c('span',{staticClass:"field__subheader--required"},[_vm._v(" * ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.header)+" "),_vm._t("icon")],2),(_vm.subheader)?_c('div',{staticClass:"field__subheader pt-2"},[_vm._v(" "+_vm._s(_vm.subheader)+" ")]):_vm._e(),_c('validation-observer',{ref:"textInput"},[_c('validation-provider',{attrs:{"rules":{
        required: _vm.required
      },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-combobox',{staticClass:"custom-dropdown mt-4",attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"multiple":_vm.multiple,"dense":"","items":_vm.items,"outlined":"","menu-props":{
          offsetY: true,
          'content-class': 'custom-dropdown',
        },"item-value":_vm.itemValue || 'value',"item-text":_vm.itemText || 'label',"append-icon":"mdi-chevron-down mt-1","error-messages":errors},on:{"change":function (newValue) { return _vm.$emit('change', newValue); },"input":function (newValue) { return _vm.$emit('input', newValue); }}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }