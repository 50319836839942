<template>
  <div class="custom-text-input">
    <div
      v-if="header || percentage"
      class="d-flex justify-space-between align-center flex-nowrap"
    >
      <div
        v-if="header"
        :class="headerClass || 'field__subheader'"
      >
        {{ header }}
      </div>
      <div
        v-if="percentage && $vuetify.breakpoint.xs"
        class="field__percentage"
      >
        {{ percentage }}
      </div>
    </div>
    <validation-observer ref="textInput">
      <validation-provider
        v-slot="{ errors }"
        :rules="{
          max: maxlength || false,
          min: minlength || false,
          passmin: passmin || false,
          required,
          password: password_to_compare || false,
          email,
        }"
        mode="eager"
      >
        <v-text-field
          v-mask="'+1 (###) ###-####'"
          :placeholder="placeholder"
          dense
          class="mt-4"
          outlined
          :error-messages="errors"
          :value="value"
          :type="type || null"
          :hint="hint"
          :persistent-hint="!!hint"
          :autocomplete="autocomplete"
          @input="(text) => $emit('input', text)"
          @change="(text) => $emit('change', text)"
          @keypress.enter="(text) => $emit('enter', text)"
          @focus="$emit('focus')"
        />
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask'

export default {
  name: "CustomTelInput",
  directives: {mask},
  props: {
    header: {
      default: () => {},
      type: String,
    },
    headerClass: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => {},
      type: String,
    },
    maxlength: {
      default: () => {},
      type: [String, Number],
    },
    minlength: {
      default: () => {},
      type: [String, Number],
    },
    passmin: {
      default: () => null,
      type: Number,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    email: {
      default: () => false,
      type: Boolean,
    },
    password_to_compare: {
      default: () => "",
      type: String,
    },
    percentage: {
      default: () => null,
      type: String,
    },
    value: {
      default: () => null,
      type: [String, Number],
    },
    type: {
      default: () => null,
      type: [String, Number],
    },
    hint: {
      default: () => "",
      type: String,
    },
    autocomplete: {
      default: "on",
      type: String,
    },
  },
};
</script>

<style lang="scss">
.custom-text-input {
  fieldset {
    border-radius: 4px !important;
    border: solid 1px #dee7ee !important;
    background-color: #ffffff !important;
  }
  input {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #9f9fb0;
  }
  .v-input__slot {
    max-height: 44px !important;
    min-height: 44px !important;
    height: 44px !important;
  }
  .v-text-field__details {
    min-height: auto;

    .v-messages {
      min-height: auto;
    }
  }
}
.field {
  &__header {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }

  &__percentage {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #2b84eb;
  }

  &__subheader {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    line-height: normal;
    color: #66788e;

    &--small {
      font-family: "Open Sans", sans-serif;
      font-size: 11px;
      line-height: normal;
      color: #66788e;
    }
  }

  &__number {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    color: #2b84eb;

    &__subheader {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      color: #66788e;
      padding-bottom: 10px;
    }
  }
}
</style>
