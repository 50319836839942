<template>
  <v-card class="filters-card px-5 py-4">
    <!-- <div class="field__header">Filter by Account</div> -->

    <v-card-text class="pa-0">
      <CustomDropdown
        v-model="selected_filter_by_account"
        header="Filter by Account"
        placeholder="Select an account"
        :items="account_filters_list"
      />
      <CustomDropdown
        v-model="selected_filter_by_event_type"
        header="Filter by Event Type"
        placeholder="Select an event"
        :items="event_type_filters_list"
      />

      <div class="d-flex justify-center full-size-block mb-2">
        <div class="simple-text-button button--red" @click="clear_filters">
          Clear Filters
        </div>
      </div>
      <v-btn
        class="custom-button custom-button--gray"
        height="34px"
        depressed
        block
        @click="apply_filters"
      >
        Apply Filters
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "FiltersPopupCard",
  components: { CustomDropdown },
  props: {
    audits: {
      default: () => [],
      type: Array,
    },
    active_filter_by_account: {
      default: () => null,
      type: String,
    },
    active_filter_by_event_type: {
      default: () => null,
      type: String,
    },
  },
  data() {
    return {
      selected_filter_by_account: null,
      selected_filter_by_event_type: null,
    };
  },
  computed: {
    account_filters_list() {
      let accounts = new Set();
      this.audits.forEach((item) => {
        accounts.add(item.account);
      });
      return Array.from(accounts);
    },
    event_type_filters_list() {
      let events = new Set();
      this.audits.forEach((item) => {
        events.add(item.eventType);
      });
      return Array.from(events);
    },
  },
  created() {
    this.selected_filter_by_account = this.active_filter_by_account;
    this.selected_filter_by_event_type = this.active_filter_by_event_type;
  },
  methods: {
    clear_filters() {
      this.$emit("clear-filters");
      this.selected_filter_by_account = null;
      this.selected_filter_by_event_type = null;
    },
    apply_filters() {
      this.$emit(
        "apply-filters",
        this.selected_filter_by_account,
        this.selected_filter_by_event_type
      );
    },
  },
};
</script>

