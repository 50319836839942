<template>
  <v-card class="custom-field-popup-card audit-history-popup">
    <v-card-title class="px-5 pt-4 pb-4">
      <div
        v-if="!JSON_show_mode"
        class="full-size-block d-flex align-center justify-space-between"
      >
        <div>Audit History</div>

        <v-menu
          :close-on-content-click="false"
          nudge-top="-30px"
          nudge-left="100px"
          max-width="250"
          min-width="250"
          open-on-click
        >
          <template #activator="{ on, attrs }">
            <div
              class="simple-text-button button--blue"
              v-bind="attrs"
              v-on="on"
            >
              Apply Filters
            </div>
          </template>
          <FiltersPopupCard
            :audits="audits"
            :active_filter_by_account="active_filter_by_account"
            :active_filter_by_event_type="active_filter_by_event_type"
            @clear-filters="clear_filters"
            @apply-filters="
              (account_type, event_type) =>
                apply_filters(account_type, event_type)
            "
          />
        </v-menu>
      </div>

      <div
        v-else
        class="d-flex"
        style="cursor: pointer"
        @click="JSON_show_mode = false"
      >
        <v-icon color="#2b84eb" size="19">
          mdi-chevron-left
        </v-icon>
        <div class="simple-text-button button--blue" style="line-height: 26px">
          Return to Audit History
        </div>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text class="pa-0">
      <v-simple-table v-if="!JSON_show_mode">
        <thead>
          <tr class="search-table__header">
            <th>
              <div class="ml-5">
                ID
              </div>
            </th>
            <th>Account</th>
            <th>Event Type</th>
            <th>Entity ID</th>
            <th>Date Time</th>
            <th />
          </tr>
        </thead>
        <tbody class="search-table__content">
          <tr v-for="(item, index) in filtered_audits" :key="index">
            <td>
              <div class="ml-5">
                {{ item.id }}
              </div>
            </td>
            <td>{{ item.account }}</td>
            <td>{{ item.eventType }}</td>
            <td>{{ item.entityID }}</td>
            <td :title="getDateWithDayNameAndTime(item.time)">
              {{ getDate(item.time) }}
            </td>
            <td>
              <div
                class="simple-text-button button--blue"
                @click="show_JSON(item.JSON)"
              >
                View JSON
              </div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div v-else class="full-size-block code-background pa-3">
        {{ active_JSON }}
      </div>
    </v-card-text>

    <v-card-actions class="shadow d-flex justify-end px-6 pb-3 pt-3">
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="$emit('dismiss')"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import FiltersPopupCard from "@/views/Users/components/FiltersPopupCard";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "AuditHistoryDialog",
  components: { FiltersPopupCard },
  mixins: [datesMixin],
  props: {
    audits: {
      default: () => [],
    },
  },
  data() {
    return {
      JSON_show_mode: false,
      active_JSON: null,
      active_filter_by_account: null,
      active_filter_by_event_type: null,
    };
  },
  computed: {
    filtered_audits() {
      if (this.active_filter_by_account || this.active_filter_by_event_type) {
        if (this.active_filter_by_account && this.active_filter_by_event_type) {
          return this.audits.filter((item) => {
            return (
              item.account === this.active_filter_by_account &&
              item.eventType === this.active_filter_by_event_type
            );
          });
        } else if (this.active_filter_by_account) {
          return this.audits.filter((item) => {
            return item.account === this.active_filter_by_account;
          });
        } else if (this.active_filter_by_event_type) {
          return this.audits.filter((item) => {
            return item.eventType === this.active_filter_by_event_type;
          });
        }
      }
      return this.audits;
    },
  },
  created() {},
  methods: {
    show_JSON(code) {
      this.active_JSON = code;
      this.JSON_show_mode = true;
    },
    clear_filters() {
      this.active_filter_by_account = null;
      this.active_filter_by_event_type = null;
    },
    apply_filters(account_type, event_type) {
      this.active_filter_by_account = account_type;
      this.active_filter_by_event_type = event_type;
    },
  },
};
</script>

<style lang="scss" scoped>
.audit-history-popup {
  .v-data-table {
    tbody {
      tr:hover {
        background: inherit !important;
      }
    }
  }
  .shadow {
    box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.11);
    margin-top: 2px;
  }
}

.full-size-block {
  height: 100%;
  width: 100%;
}
.code-background {
  background-color: #f9f9f9;
  font-family: SFMonoPowerline;
  font-size: 12px;
  font-weight: 500;
  color: #66788e;
}
</style>
