var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-text-input"},[(_vm.header || _vm.percentage)?_c('div',{staticClass:"d-flex justify-space-between align-center flex-nowrap"},[(_vm.header)?_c('div',{class:_vm.headerClass || 'field__subheader'},[_vm._v(" "+_vm._s(_vm.header)+" ")]):_vm._e(),(_vm.percentage && _vm.$vuetify.breakpoint.xs)?_c('div',{staticClass:"field__percentage"},[_vm._v(" "+_vm._s(_vm.percentage)+" ")]):_vm._e()]):_vm._e(),_c('validation-observer',{ref:"textInput"},[_c('validation-provider',{attrs:{"rules":{
        max: _vm.maxlength || false,
        min: _vm.minlength || false,
        passmin: _vm.passmin || false,
        required: _vm.required,
        password: _vm.password_to_compare || false,
        email: _vm.email,
      },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+1 (###) ###-####'),expression:"'+1 (###) ###-####'"}],staticClass:"mt-4",attrs:{"placeholder":_vm.placeholder,"dense":"","outlined":"","error-messages":errors,"value":_vm.value,"type":_vm.type || null,"hint":_vm.hint,"persistent-hint":!!_vm.hint,"autocomplete":_vm.autocomplete},on:{"input":function (text) { return _vm.$emit('input', text); },"change":function (text) { return _vm.$emit('change', text); },"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (text) { return _vm.$emit('enter', text); })($event)},"focus":function($event){return _vm.$emit('focus')}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }